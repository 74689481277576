import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 查询商品组列表
 * @param params
 * @returns {Promise<*>}
 */
export const getGroupListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/shopQuery?brandIds='+params.brandIds+'&attributeIds='+params.attributeIds+'&isSale='+params.isSale+'&isNew='+params.isNew+'&isOld='+params.isOld+'&isStar='+params.isStar+'&isShow='+params.isShow+'&isSole='+params.isSole+'&price2='+params.price2+'&cid1='+params.cid1+'&cid2='+params.cid2+'&cid3='+params.cid3+'&cid4='+params.cid4+'&cid5='+params.cid5+'&size='+params.size+'&page='+params.page+'&status=0'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询offers列表
 * @param params
 * @returns {Promise<*>}
 */
export const getOffersListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/offers/getAll'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 分页获取待处理的商品列表
 * @param params
 * @returns {Promise<*>}
 */
export const getSupGroupService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/spu/marge?ids='+params.ids))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get设置商品组标签
 * @param params
 * @returns {Promise<*>}
 */
export const getGroupLabelService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/set/label?id='+params.id+'&state='+params.state+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询商品组信息
 * @param params
 * @returns {Promise<*>}
 */
export const getGroupInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/id?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 修改商品组图片
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupInfoImageService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/update/spu/img?id='+params.id+'&ids='+params.ids))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 修改商品组标题
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupInfoTitleService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/update/spu/title?id='+params.id+'&spuId='+params.spuId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 修改商品组详情
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupInfoDetailsService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/update/spu/detail?id='+params.id+'&spuIds='+params.spuIds))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 修改商品分类属性信息
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupCategoryService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/group/update/category/attr'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 修改信息列表
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupSpuModelService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/group/update/spu/model'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 修改信息列表 分离
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupSeparateService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/separate?id='+params.id+'&supId='+params.supId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 修改信息列表 移动
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupMoveService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/move?id='+params.id+'&supId='+params.supId+'&productId='+params.productId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 修改信息列表发布
 * @param params
 * @returns {Promise<*>}
 */
export const updateGroupOffService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/group/update/spu/off'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
