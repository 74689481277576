const EVENT_NAME = {
    /**
     * 登录、注册组件状态
     */
    LOGIN_SUBASSEMBLY_STATUS:'LOGIN_SUBASSEMBLY_STATUS',
    /**
     * kol搜索
     */
    KOL_SEARCH_LIST:'KOL_SEARCH_LIST',
    /**
     * 文章搜索列表
     */
    ARTICLE_SEARCH_LIST:'ARTICLE_SEARCH_LIST',
    /**
     * 媒体库搜索列表
     */
    MEDIAL_LIBRARY_LIST:'MEDIAL_LIBRARY_LIST',
    /**
     * 平台筛选列表
     */
    PLATFORM_SCREENING_LIST:'PLATFORM_SCREENING_LIST',
    /**
     * 媒体人列表
     */
    MEDIA_PEOPLE_LIST:'MEDIA_PEOPLE_LIST',
    /**
     * 报道列表
     */
    REPORT_LIST:'REPORT_LIST',
    /**
     * 媒体库导出表单
     */
    MEDIAL_LIBRARY_EXPORT_FORM:'MEDIAL_LIBRARY_EXPORT_FORM',
    /**
     * 媒体库导出全部
     */
    MEDIAL_LIBRARY_EXPORT_ALL:'MEDIAL_LIBRARY_EXPORT_ALL',
    /**
     * 榜单查询
     */
    KOL_RANKING_QUERY:'KOL_RANKING_QUERY',
    /**
     * 效果指数数据更新
     */
    EVALUATION_UPDATE:'EVALUATION_UPDATE',
    /**
     * 粉丝趋势数据更新
     */
    FANS_TREND_UPDATE:'FANS_TREND_UPDATE',
    /**
     * 媒体库搜索列表 区分公司媒体库、敏感媒体库
     */
    MEDIAL_LIBRARY_LIST_NAME:'MEDIAL_LIBRARY_LIST_NAME',
    /**
     * 榜单关键字查询
     */
    KOL_RANKING_KEYWORD:'KOL_RANKING_KEYWORD',
    /**
     * 清除掉关键词
     */
    CLEAR_KEY_WORD:'CLEAR_KEY_WORD',


    /**
     * 选择分类
     */
    SELECT_CLASSIFY_TYPE:'SELECT_CLASSIFY_TYPE',
    /**
     * 关闭选择商品弹框
     */
    SELECT_PRODUCT_CLOSE:'SELECT_PRODUCT_CLOSE'
}
export default EVENT_NAME
