/**
 * 商品分类一级菜单
 * @type {({})[]}
 */
export const classification = [
    {
        id:1,
        name:'Women',
        checkBox:0,
    },
    {
        id:2,
        name:'Men',
        checkBox:0,
    },
    {
        id:3,
        name:'Kids',
        checkBox:0,
    },
    {
        id:4,
        name:'Home',
        checkBox:0,
    },
]

/**
 * 选择折扣数据
 * @type {*[]}
 */
export const discountList = [
    {
        id:1,
        name:'10% off',
    },
    {
        id:2,
        name:'20% off',
    },
    {
        id:3,
        name:'30% off',
    },
    {
        id:4,
        name:'40% off',
    },{
        id:5,
        name:'50% off',
    }
]
