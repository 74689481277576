/**
 * 分类列表
 * @type {{data(): {}}}
 */
const TypeDown = {
    name:'TypeDown',
    props:{
        /**
         * 监听选中的数据
         */
        value: {
            type: Number | String,
        },
        /**
         * 监听分类类型
         */
        type:{
            type:Number,
        },
        /**
         * 识别属性
         */
        attr:{
            type:Boolean
        },
        /**
         * title Id
         */
        titleId:{
            type:Number | String,
        },
        /**
         * title
         */
        title:{
            type:String,
        },
        /**
         * 组件自定义宽度
         */
        width:{
            type:Number
        },
        /**
         * 组件自定义margin-right
         */
        marginRight:{
            type:Number
        },
        /**
         * 自定义数据
         */
        data:{
            type:Array,
        }
    },
    data(){
        return {
            typeVisible:false,
            defaultSelect:''
        }
    },
    created() {
        for(let i in this.data){
            if(this.data[i].id == this.value){
                this.defaultSelect = this.data[i].name
            }
        }
    },
    mounted() {
        /**
         * 点击空白处消除弹框
         */
        document.addEventListener('click',(e) => {
            let thisClassName = e.target.className;
            if(thisClassName !== 'title type-down-title' && thisClassName !== 'name type-down-name' && thisClassName !== 'down type-down-i'){
                this.typeVisible = false
            }
        })
    },
    methods:{
        /**
         * 选中的数据
         * @param obj
         */
        onSelect(obj){
            this.defaultSelect = obj.name
            console.log(obj)
            let object
            if(this.attr){
                 object = {
                     titleId:this.titleId,
                     id:obj.id,
                     attr:true,
                }
            }else{
                 object = {
                     id:obj.id,
                     cid:this.type,
                     attr:false
                }
            }
            this.$emit('input',object.id)
        },
        /**
         * 清空
         */
        onClear(){
            this.typeVisible = false
            this.defaultSelect = ''
            this.$emit('input','')
        },
    },
    watch: {
        value(val) {
            for(let i in this.data){
                if(this.data[i].id === val){
                    this.defaultSelect = this.data[i].name
                }
                if(val === null){
                    this.defaultSelect = ''
                }
            }
        },
    }
}
export default TypeDown
