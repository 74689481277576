<template>
    <div class="menu-template">
        <div class="menu-select-type">
            <span class="menu-classify-type">{{ menuName }}</span>
            <div class="menu-classify-select">
                <span class="type-name" :key="'productType'+key" v-for="(product,key) in productType" @click="onCloseScreen(product.id)">{{ product.name }} <i class="close"></i></span>
                <span class="type-name" :key="'productClassify'+key" v-for="(classify,key) in productClassify" @click="onCloseClassify(classify)">{{ classify.name }} <i class="close"></i></span>
                <span class="type-name" :key="'productBrands'+key" v-for="(brands,key) in productBrands" @click="onCloseBrands(brands.id)">{{ brands.name }} <i class="close"></i></span>
                <span class="type-name" :key="'productNewIn'+key" v-for="(newIn,key) in productNewIn" @click="onCloseNewIn(newIn)">{{ newIn.name }} <i class="close"></i></span>
            </div>
        </div>
        <div class="menu-box" :key="key" v-for="(menuType,key) in menuTypeArray">
            <div class="menu-thead"  @click="onMenuVisible(menuType.id)">
                <span class="menu-name">{{ menuType.name }}</span>
                <i :class="menuType.visible? 'menu-up':'menu-down'"></i>
            </div>
            <div class="menu-search" v-if="menuType.name === 'Brand' && menuType.visible">
                <div class="menu-input">
                    <input type="text" v-model="searchVal" @keyup="onSearch(menuType,key)" class="input" placeholder="Search"/>
                </div>
                <a href="javascript:" class="icon"></a>
            </div>
            <div class="menu-body" :class="menuType.name === 'Brand'?'menu-brands-scroll':''" v-show='menuType.visible'>
                <ul>
                    <li :key="'menu'+index" v-for="(menu,index) in onMenuTypeList(menuType)" :style="menu.isParent !== 1?'padding:10px':''">
                        <div class="label">
                            <i class="label-icon" v-if="menu.isParent !== 1"  @click="onSelectType(menu,menuType)" :class="menu.checkBox?'select':'selected'"></i>
                            <a href="javascript:" @click="onSelectType(menu,menuType)">{{ menu.name }}</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="menu-body" v-if="menuType.name === 'Price'"  v-show='menuType.visible'>
                <div class="menu-slider"> <Slider @on-change="onPrice" v-model="prizeArray" show-tip="never" :step="1" range :min="0" :max="99999"></Slider></div>
                <div class="menu-slider-number">
                    <span class="slider-left">{{ prizeArray[0] }}</span>
                    <span class="slider-right">{{ prizeArray[1] }}+</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductMenu from './productMenu'
export default ProductMenu
</script>
<style lang="less">
@import "productMenu";
</style>
