/**
 * 欢迎数据集
 * @type {({})[]}
 */
export const welcomeList = [
    {
        value:1,
        label:'Auto reply',
    },
]
/**
 * edm 分类数据集
 * @type {({})[]}
 */
export const edmTypeList = [
    {
        value:5,
        label:'EDM A',
    },
    {
        value:6,
        label:'EDM B',
    },
    {
        value:7,
        label:'EDM C',
    },
]

/**
 * 欢迎表头文件
 * @type {{}[]}
 */
export const welcomeColumns = [
    {
        title: '#',
        width:140,
        key: 'id'
    },
    {
        title: 'Start',
        width:180,
        slot:'startTime',
    },
    {
        title: 'Title',
        key: 'emailTitle',
        width:220,
    },
    {
        title: 'Status',
        slot:'status',
    },
    {
        type: 'selection',
        width:60,
        align: 'center'
    },
    {
        title:' ',
        slot:'btn',
    }
]
/**
 * edm表头文件
 * @type {{}[]}
 */
export const edmColumns = [
    {
        title: 'ID',
        width:200,
        key: 'planId'
    },
    {
        title: 'Send on',
        width:180,
        slot:'sendTime',
    },
    {
        title: 'Title',
        key: 'emailTitle',
        width:200,
    },
    {
        title: 'Status',
        slot:'status',
    },
    {
        type: 'selection',
        width:60,
        align: 'center'
    },
    {
        title:' ',
        slot:'btn',
    }
]

/**
 * 语言
 * @type {({})[]}
 */
export const language = [
    {
        name:'English',
        code:'En',
        checkBox:1
    },
    {
        name:'中文',
        code:'Cn',
        checkBox:1
    }
]

/**
 * 选择终端
 * @type {({})[]}
 */
export const terminal = [
    {
        name:'Web',
        id:1,
    },
    {
        name:'H5',
        id:2,
    },
    // {
    //     name:'iOS',
    //     id:3,
    // },
    // {
    //     name:'Android',
    //     id:4,
    // }
]
/**
 * 选择内容
 * @type {({})[]}
 */
export const content = [
    {
        name:'Link',
        id:1,
    },
    {
        name:'Text',
        id:2,
    },
    {
        name:'Selection',
        id:3,
    },
]

/**
 * 文字大小
 * @type {{}[]}
 */
export const sizeList = [
    {
        value:'18',
    },
    {
        value:'21',
    },
    {
        value:'24',
    },
    {
        value:'36',
    },
    {
        value:'40',
    },
    {
        value:'48',
    }
]

/**
 * 副标题文字大小
 * @type {{}[]}
 */
export const sizeSubList = [
    {
        value:'12',
    },
    {
        value:'14',
    },
    {
        value:'16',
    },
    {
        value:'18',
    },
    {
        value:'21',
    },
    {
        value:'24',
    }
]

/**
 * 间距大小
 * @type {{}[]}
 */
export const leadingList = [
    {
        value:'18',
    },
    {
        value:'21',
    },
    {
        value:'24',
    },
    {
        value:'36',
    },
    {
        value:'40',
    },
    {
        value:'48',
    }
]
/**
 * 位置
 * @type {{}[]}
 */
export const locationList = [
    {
        value:'Left',
    },
    {
        value:'Middle',
    },
    {
        value:'Right',
    }
]

/**
 * 颜色
 * @type {{}[]}
 */
export const colorList = [
    {
        value:'ffffff',
    },
    {
        value:'000000',
    },
    {
        value:'d20000',
    },
    {
        value:'ee9300',
    },
    {
        value:'f5e900',
    },
    {
        value:'ffa6d3',
    },
    {
        value:'006934',
    },
    {
        value:'0027e8',
    },
    {
        value:'6c00b2',
    }
]

/**
 * 图文结构体
 * @type {*[]}
 */
export const imageTextList =  {
    "cColour": "",
    "cLeading": "",
    "cLocation": "",
    "cSize": "",
    "content": "",
    "contentId": null,
    "createTime": "",
    "description": "",
    "enabled": null,
    "id": null,
    imageList: [
        {
            "bColour": "",
            "bLeading": "",
            "bLocation": "",
            "bSize": "",
            "brand": "",
            "createTime": "",
            "dColour": "",
            "dLeading": "",
            "dLocation": "",
            "dSize": "",
            "description": "",
            "enabled": null,
            "id": null,
            "image": "",
            "sort": null,
            "textId": null,
            "updateTime": ""
        },
        {
            "bColour": "",
            "bLeading": "",
            "bLocation": "",
            "bSize": "",
            "brand": "",
            "createTime": "",
            "dColour": "",
            "dLeading": "",
            "dLocation": "",
            "dSize": "",
            "description": "",
            "enabled": null,
            "id": null,
            "image": "",
            "sort": null,
            "textId": null,
            "updateTime": ""
        },
        {
            "bColour": "",
            "bLeading": "",
            "bLocation": "",
            "bSize": "",
            "brand": "",
            "createTime": "",
            "dColour": "",
            "dLeading": "",
            "dLocation": "",
            "dSize": "",
            "description": "",
            "enabled": null,
            "id": null,
            "image": "",
            "sort": null,
            "textId": null,
            "updateTime": ""
        }
    ],
    "sort": null,
    "updateTime": ""
}

/**
 * edm排序
 * @type {*[]}
 */
export const edmSort = [
    {
        value:'by us',
        label:1
    },
    {
        value:'by clients',
        label:2
    },
    {
        value:'Mixed',
        label:3
    }
]
