<template>
    <div class="product-type">
        <div class="type-box" @click="classifyVisible?classifyVisible = false:classifyVisible = true">
            <span class="type-name">{{ classifySelect.label }}</span>
            <i class="type-icon"></i>
        </div>
        <!--s List-->
        <div class="type-list" v-show="classifyVisible">
            <a href="javascript:" @click="onSelectType(classify)" :key="key" v-for="(classify,key) in classifyObj">{{ classify.label }}</a>
        </div>
        <!--e List-->
        <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
    </div>
</template>
<script>
import ProductType from './productType'
export default ProductType
</script>
<style lang="less">
@import "productType";
</style>
