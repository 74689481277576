/**
 * 商品分类
 * @type {()[]}
 */
export const classify = [
    {
        'label' : 'Women',
        'value': 1
    },
    {
        'label' : 'Men',
        'value': 2
    },
    {
        'label' : 'Kids',
        'value': 3
    },
    {
        'label' : 'Home',
        'value': 4
    }
]

/**
 * 商品菜单
 * @type {*[]}
 */
export const menu = [
    {
        'id' : 1,
        'name' : "Category",
        'Children':[]
    },
    {
        'id' : 2,
        'name' : "Brand",
        'Children':[]
    },
    {
        'id' : 3,
        'name' : "Size",
        'Children':[]
    },
    {
        'id' : 4,
        'name' : "Colour",
        'Children':[]
    },
    {
        'id' : 5,
        'name' : "Price",
        'Children':[]
    },
    {
        'id' : 6,
        'name' : "New in / Sale / Pre-owned",
        'Children':[]
    },
    {
        'id' : 7,
        'name' : "Store",
        'Children':[]
    }
]
