/**
 * 商品菜单
 * @type {{data(): {}}}
 */
import Vue from 'vue'
import { Slider } from 'iview';
Vue.component('Slider', Slider);
import { getMenuListService } from "@/service/menu-service";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
const ProductMenu = {
    name:'ProductMenu',
    data(){
        return {
            prizeArray:[0,99999],
            menuTypeArray:[], //菜单对象
            menuName:'',
            productClassify:[], //商品分类对象
            productType:[], //商品属性对象
            productBrands:[], //商品品牌对象
            productNewIn:[], //商品折扣新款古着
            searchVal:'', //搜索
            searchList:[], //存放搜索的总数据
            selectNewIn:{
                isNew:0,
                isSale:0,
                isOld:0,
            }
        }
    },
    created() {
        /**
         * 订阅商品菜单事件
         */
        $event.$on(EVENT_NAME.SELECT_CLASSIFY_TYPE, (value) => {
            this.onMenuList(value).then()
            this.menuName = ''
            this.productClassify = []
            this.productType = []
            this.productBrands = []
            this.productNewIn = []
        });
        this.onMenuList(1).then()
    },
    methods:{
        /**
         * 搜素
         * @param obj
         * @param key
         */
        onSearch(obj,key){
            this.menuTypeArray[key].brandlist = this.onFilter()
        },
        /**
         * 过滤列表相同名称
         * @param content
         * @returns {*[]}
         */
        onFilter(){
            return this.searchList.filter((value)=>{  //过滤数组元素
                return value.name.includes(this.searchVal); //如果包含字符返回true
            });
        },
        /**
         * 展开
         * @param id
         */
        onMenuVisible(id) {
            for(let i in this.menuTypeArray){
                if(id === this.menuTypeArray[i].id){
                    this.menuTypeArray[i].visible?this.menuTypeArray[i].visible = false : this.menuTypeArray[i].visible = true
                }
            }
        },
        onMenuTypeList(obj){
            if(obj.name === 'Category'){
                return obj.children;
            }
            if(obj.name === 'Brand'){
                return obj.brandlist;
            }
            if(obj.name === 'Size'){
                return obj.sizeList;
            }
            if(obj.name !== 'Category' && obj.name !== 'Brand' && obj.name !== 'Size'){
                return obj.attrList;
            }
        },
        /**
         * 选择分类
         * @param obj
         */
        onSelectType(obj,menuType) {
            if(menuType.name === 'Category'){
                if(obj.isParent ===0){
                    obj.checkBox = !obj.checkBox;
                    if(this.productClassify.length === 0){
                        this.productClassify.push(obj)
                    }else{
                        let index = this.productClassify.findIndex((val) => val.id === obj.id)
                        if(this.productClassify.findIndex((val) => val.id === obj.id) !==-1){
                            this.productClassify.splice(index,1)
                        }else{
                            this.productClassify.push(obj)
                        }
                    }
                    let object = {
                        level:obj.level,
                        isParent:obj.isParent,
                        list:this.productClassify
                    }
                    this.$emit('on-select-type',object)
                }else{
                    this.$emit('on-select-type',obj)
                    this.menuName = obj.name
                    this.onMenuList(obj.id).then()
                }
            }
            if(menuType.name === 'Brand'){
                this.onSelectBrands(obj)
            }
            if(menuType.name !== 'Category' && menuType.name !== 'Brand' && menuType.name !== 'New in / Sale / Pre-Owned' && menuType.name !== 'Size' ){
                this.onSelectAttribute(obj,menuType)
            }
            if(menuType.name === 'New in / Sale / Pre-Owned'){
                this.onSelectNewIn(obj)
            }
        },
        /**
         * 删除分类筛选条件
         * @param id
         */
        onCloseClassify(classify){
            for(let i in this.productClassify){
                if(classify.id === this.productClassify[i].id){
                    this.productClassify.splice(i,1)
                }
            }
            for(let i in this.menuTypeArray){
                for(let j in this.menuTypeArray[i].children){
                    if(classify.id === this.menuTypeArray[i].children[j].id){
                        this.menuTypeArray[i].children[j].checkBox = false
                    }
                }
            }
            let object = {
                level:classify.level,
                isParent:classify.isParent,
                list:this.productClassify
            }
            this.$emit('on-select-type',object)
        },
        /**
         * 属性筛选条件
         */
        onSelectAttribute(obj){
            obj.checkBox = !obj.checkBox;
            if(this.productType.length === 0){
                this.productType.push(obj)
            }else{
                let index = this.productType.findIndex((val) => val.id === obj.id)
                if(this.productType.findIndex((val) => val.id === obj.id) !==-1){
                    this.productType.splice(index,1)
                }else{
                    this.productType.push(obj)
                }
            }
            this.$emit('on-select-attribute',this.productType)
        },
        /**
         * 删除属性筛选条件
         * @param id
         */
        onCloseScreen(id) {
            for(let i in this.productType){
                if(id === this.productType[i].id){
                    this.productType.splice(i,1)
                }
            }
            for(let i in this.menuTypeArray){
                if(this.menuTypeArray[i].attrList){
                    for(let j in this.menuTypeArray[i].attrList){
                        if(id === this.menuTypeArray[i].attrList[j].id){
                            this.menuTypeArray[i].attrList[j].checkBox = false
                            this.$forceUpdate()
                        }
                    }
                }
            }
            this.$emit('on-select-attribute',this.productType)
        },
        /**
         * 品牌筛选条件
         * @param obj
         */
        onSelectBrands(obj){
            obj.checkBox = !obj.checkBox;
            if(this.productBrands.length === 0){
                this.productBrands.push(obj)
            }else{
                let index = this.productBrands.findIndex((val) => val.id === obj.id)
                if(this.productBrands.findIndex((val) => val.id === obj.id) !==-1){
                    this.productBrands.splice(index,1)
                }else{
                    this.productBrands.push(obj)
                }
            }
            this.$emit('on-select-brands',this.productBrands)
        },
        /**
         * 删除品牌筛选条件
         * @param id
         */
        onCloseBrands(id){
            for(let i in this.productBrands){
                if(id === this.productBrands[i].id){
                    this.productBrands.splice(i,1)
                }
            }
            for(let i in this.menuTypeArray){
                if(this.menuTypeArray[i].brandlist){
                    for(let j in this.menuTypeArray[i].brandlist){
                        if(id === this.menuTypeArray[i].brandlist[j].id){
                            this.menuTypeArray[i].brandlist[j].checkBox = false
                            this.$forceUpdate()
                        }
                    }
                }
            }
            this.$emit('on-select-brands',this.productBrands)
        },
        /**
         * 新款古着折扣条件筛选
         * @param obj
         */
        onSelectNewIn(obj){
            obj.checkBox = !obj.checkBox;
            if(this.productNewIn.length === 0){
                this.productNewIn.push(obj)
            }else{
                let index = this.productNewIn.findIndex((val) => val.id === obj.id)
                if(this.productNewIn.findIndex((val) => val.id === obj.id) !==-1){
                    this.productNewIn.splice(index,1)
                }else{
                    this.productNewIn.push(obj)
                }
            }
            if(obj.name === 'New in'){
                if(obj.checkBox){
                    this.selectNewIn.isNew = 1
                }else{
                    this.selectNewIn.isNew = 0
                }
            }
            if(obj.name === 'Sale'){
                if(obj.checkBox){
                    this.selectNewIn.isSale = 1
                }else{
                    this.selectNewIn.isSale = 0
                }
            }
            if(obj.name === 'Pre-Owned'){
                if(obj.checkBox){
                    this.selectNewIn.isOld = 1
                }else{
                    this.selectNewIn.isOld = 0
                }
            }
            this.$emit('on-select-new-in',this.selectNewIn)
        },
        /**
         * 删除折扣新款古着
         * @param id
         */
        onCloseNewIn(obj){
            for(let i in this.productNewIn){
                if(obj.id === this.productNewIn[i].id){
                    this.productNewIn.splice(i,1)
                }
            }
            for(let i in this.menuTypeArray){
                for(let j in this.menuTypeArray[i].attrList){
                    if(obj.id === this.menuTypeArray[i].attrList[j].id){
                        this.menuTypeArray[i].attrList[j].checkBox = false
                    }
                }
            }
            if(obj.name === 'New in'){
                if(obj.checkBox){
                    this.selectNewIn.isNew = 1
                }else{
                    this.selectNewIn.isNew = 0
                }
            }
            if(obj.name === 'Sale'){
                if(obj.checkBox){
                    this.selectNewIn.isSale = 1
                }else{
                    this.selectNewIn.isSale = 0
                }
            }
            if(obj.name === 'Pre-Owned'){
                if(obj.checkBox){
                    this.selectNewIn.isOld = 1
                }else{
                    this.selectNewIn.isOld = 0
                }
            }
            this.$emit('on-select-new-in',this.selectNewIn)
        },
        /**
         * 选择价格
         * @param price
         */
        onPrice(price) {
            this.$emit('on-price',price)
        },
        /**
         * 设置商品组标签
         * @returns {Promise<void>}
         */
        async onMenuList(id){
            try {
                let params = {
                    id:id,
                }
                const { code,data } = await getMenuListService(params)
                if( code === 1){
                    data[0].visible = true
                    for(let i in data){
                        if(data[i].name === 'Category'){
                            for(let j in data[i].children){
                                if(data[i].children[j].isParent === 0){
                                    data[i].children[j].checkBox = false
                                }
                            }
                        }
                        if(data[i].name === 'Brand'){
                            this.searchList = data[i].brandlist
                        }
                    }
                    this.menuTypeArray = data
                }

            }catch (error){
                console.log(error)
            }
        }
    }
}
export default ProductMenu
