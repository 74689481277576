/**
 * 属性列表
 * @type {{data(): {}}}
 */
const StyleDown = {
    name:'StyleDown',
    props:{
        /**
         * 监听选中的数据
         */
        value: {
            type: Number | String,
        },
        /**
         * title
         */
        title:{
            type:String,
        },
        /**
         * 组件自定义宽度
         */
        width:{
            type:Number
        },
        /**
         * 组件自定义margin-right
         */
        marginRight:{
            type:Number
        },
        /**
         * 自定义数据
         */
        data:{
            type:Array,
        },
        /**
         * 颜色
         */
        color:{
            type:Boolean
        },
        /**
         * 不可点击
         */
        disabled:{
            type:Boolean
        },
    },
    data(){
        return {
            typeVisible:false,
            defaultSelect:''
        }
    },
    created() {
        for(let i in this.data){
            if(this.data[i].value === this.value){
                this.defaultSelect = this.data[i].value
            }
        }
    },
    mounted() {
        /**
         * 点击空白处消除弹框
         */
        document.addEventListener('mouseup',(e) => {
            let thisId = document.getElementById('typeId')
            if(thisId){
                if(!thisId.contains(e.target)){
                    this.typeVisible = false
                }
            }
        })
    },
    methods:{
        /**
         * 选中的数据
         * @param obj
         */
        onSelect(value){
            this.typeVisible = false
            this.defaultSelect = value
            this.$emit('input',value)
        },
        /**
         * 清空
         */
        onClear(){
            this.typeVisible = false
            if(this.color){
                this.defaultSelect = 'fff'
            }else{
                this.defaultSelect = ''
            }
            this.$emit('input','')
        },
    },
    watch: {
        value(val) {
            for(let i in this.data){
                if(this.data[i].value === val){
                    this.defaultSelect = this.data[i].value
                }
            }
        },
    }
}
export default StyleDown
