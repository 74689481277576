import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 获取欢迎列表
 * @param params
 * @returns {Promise<*>}
 */
export const getEmailAutoListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/email/auto/get/type?type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 修改欢迎列表状态
 * @param params
 * @returns {Promise<*>}
 */
export const getEmailAutoEditStatusService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/email/auto/update/status?id='+params.id+'&status='+params.status))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * delete 删除欢迎列表数据
 * @param params
 * @returns {Promise<*>}
 */
export const delEmailAutoListService = async (params) => {
    try {
        const { data } = await request.delete(evnUrl.concat('/api/email/auto?ids='+params.ids))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 获取edm列表
 * @param params
 * @returns {Promise<*>}
 */
export const getEdmListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/email/market/get/type?type='+params.type+'&cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 修改欢迎列表状态
 * @param params
 * @returns {Promise<*>}
 */
export const getEdmEditStatusService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/email/market/update/status?id='+params.id+'&status='+params.status))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * delete 删除edm列表数据
 * @param params
 * @returns {Promise<*>}
 */
export const delEdmListService = async (params) => {
    try {
        const { data } = await request.delete(evnUrl.concat('/api/email/market?ids='+params.ids))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 获取reply edit数据
 * @param params
 * @returns {Promise<*>}
 */
export const getReplyEditService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/email/auto/id?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 编辑Reply数据
 * @param params
 * @returns {Promise<*>}
 */
export const editReplyService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/email/auto'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * get 获取edm edit数据
 * @param params
 * @returns {Promise<*>}
 */
export const getEdmEditService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/email/market/id?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 编辑edm数据
 * @param params
 * @returns {Promise<*>}
 */
export const editEdmService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/email/market'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 获取edm 模版数据
 * @param params
 * @returns {Promise<*>}
 */
export const getEdmDataTemplateService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/email/market/dataTemplate'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
