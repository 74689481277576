<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <ProductType back="close" @on-select-type="onSelectType"/>
        <!--e: Type-->

        <!--s: Data-->
        <div class="product-prompt">
            <div class="product-discount">
                <div class="label" :key="key" v-for="(discount,key) in discountList" @click="onDiscount(discount.id)">
                    <i class="label-icon" :class="productParams.discount === discount.id?'select':'selected'"></i>
                    <a href="javascript:">{{ discount.name }}</a>
                </div>
            </div>
            <div class="product-search-box">
                <div class="search-style">
                    <div class="label" @click="onSelectSearchType('isStar')">
                        <i class="label-icon" :class="productParams.isStar === 0?'selected':'select'"></i>
                        <i class="label-image star"></i>
                    </div>
                    <div class="label" @click="onSelectSearchType('isShow')">
                        <i class="label-icon" :class="productParams.isShow === 0?'selected':'select'"></i>
                        <i class="label-image draft"></i>
                    </div>
                    <div class="label" @click="onSelectSearchType('isSole')">
                        <i class="label-icon" :class="productParams.isSole === 0?'selected':'select'"></i>
                        <i class="label-image sole"></i>
                    </div>
                </div>
                <div class="search-input">
                    <Input icon="ios-search" class="text"></Input>
                </div>
            </div>
        </div>
        <!--e: Data-->

        <!--s: Body-->
        <div class="product-body">

            <!--s: Menu-->
            <div class="product-menu">

                <!--s: Menu Box-->
                <ProductMenu @on-price="onPrice" @on-select-type="onSelectClassify" @on-select-brands="onSelectBrands" @on-select-attribute="onSelectAttribute" @on-select-new-in="onSelectNewIn"/>
                <!--e: Menu Box-->

                <!--s: Handle-->
                <div class="product-handle">
                    <ul>
                        <li><a href="javascript:" @click="onSave">Save</a></li>
                    </ul>
                </div>
                <!--e: Handle-->

            </div>
            <!--e: Menu-->

            <!--s: List-->
            <div class="product-list">
                <Spin fix v-if="loadingVisible">
                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                    <div>Loading</div>
                </Spin>
                <ul>
                    <li class="list-li" :key="key" v-for="(productList,key) in productList.content" :style="productList.checked?'background-color:#e5e5e5':''">
                        <div class="list-handle">
                            <a href="javascript:" class="handle-unified star" @click="onGroupLabel(productList.id,productList.isStar,1)" :class="productList.isStar === 1?'star':'star-grey'"></a>
                            <a href="javascript:" class="handle-unified draft" @click="onGroupLabel(productList.id,productList.isShow,2)" :class="productList.isShow === 1?'draft':'draft-grey'"></a>
                            <a href="javascript:" class="handle-unified sole" @click="onGroupLabel(productList.id,productList.isSole,3)" :class="productList.isSole === 1?'star':'sole-grey'"></a>
                        </div>
                        <div class="list-text" @click="onProductSelect(productList)">
                            <div class="list-img" :style="'background-image: url('+productList.images+')'"></div>
                            <div class="list-box">
                                <div class="list-status">
                                  <span class="is-new" v-if="productList.isNew === 1">New in</span>
                                  <span class="is-sale" v-if="productList.isSale === 1">Sale</span>
                                </div>
                                <span class="box-text bold">{{ productList.name }}</span>
                                <span class="box-text">{{ productList.details }}</span>
                                <span class="box-text bold">{{productList.currency}}{{ productList.price }}</span>
                                <span class="box-text">{{ productList.productId }}</span>
                            </div>
                        </div>
                    </li>
                </ul>

                <!--s: page-->
                <div class="product-page"><Page :total="productList.totalElements" :current="productParams.currentPage" :page-size="productParams.pageSize"  @on-change="onSearchPage" @on-page-size-change="onSearchPageSize" size="small"></Page></div>
                <!--e: page-->

            </div>
            <!--e: List-->
        </div>
        <!--e: Body-->

    </div>
</template>
<script>
import ProductSelect from './productSelect'
export default ProductSelect
</script>
<style lang="less">
@import "productSelect";
</style>
