<template>
    <div class="type-down" :class="disabled?'type-disabled':''" :style="'width:'+width+'px;margin-right:'+marginRight+'px'">
        <div class="title type-down-title" :class="value !=='' && !disabled?'type-hover':''"  @click="!disabled?typeVisible = true:null">
            <span class="name type-down-name">{{ title }}<i class="clear" @click.stop="onClear"></i></span>
            <i class="down type-down-i"></i>
        </div>
        <div class="text" v-if="!color">{{ defaultSelect }}</div>
        <div class="color" v-if="color" :style="'background:#'+defaultSelect"></div>
        <div :class="typeVisible && !color?'type-select':'type-select-color'" v-show="typeVisible" id="typeId">
            <ul>
                <li :class="value === dataType.value?'active':''" :key="key" v-for="(dataType,key) in data" @click="onSelect(dataType.value)" :style="typeVisible && color?'background:#'+dataType.value:''">{{ typeVisible && !color?dataType.value:'' }}</li>
            </ul>
        </div>
    </div>
</template>
<script>
import StyleDown from './styleDown'
export default StyleDown
</script>
<style lang="less">
@import "styleDown";
</style>
