<template>
    <div class="layout-body" v-if="editObj.en">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Launch -->
        <div class="launch-body">

            <div class="edit-top">
                <span class="name">{{ launchName }}</span>
            </div>

            <div class="edit-check edit-border-bottom">
                <div class="box">
                    <ul>
                        <li :key="key" v-for="(lang,key) in language">
                            <i class="label-icon" :class="lang.checkBox?'select':'selected'"></i>
                            <span class="label-name" :class="lang.checkBox?'title':''">{{ lang.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="edit-check edit-data">
                <ul>
                    <li>
                        <div class="title">
                            <span class="name">Start</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.startTime" placement="bottom-start" type="date" @on-change="onStartMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.startTime) }}</div>
                    </li>
                    <li>
                        <div class="title">
                            <span class="name">End</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.endTime" placement="bottom-start" type="date" @on-change="onEndMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.endTime) }}</div>
                    </li>
                    <li>
                        <TypeDown v-model="editObj.en.confId"  title="Send by" :width="120" :margin-right="25" :data="editObj.en.emailConfList" />
                    </li>
                </ul>
            </div>

            <!--s: cover-->
            <div class="launch-box">
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Email title</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.emailTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">邮件标题</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.emailTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <h1 class="headline">Cover</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>

                <div class="launch-plug" v-if="launchTitle === 'welcome'">
                    <StyleDown v-model="editObj.en.ctSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.ctLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.ctLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.ctColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    <div class="launch-upload">
                        <span class="title">Cover image - 1270 x 480</span>
                        <div class="upload">
                            <span class="value">{{ editObj.en.coverImage }}</span>
                            <Button>Upload<input type="file" class="input" @change="onFileDataChange"/></Button>
                        </div>
                    </div>
                </div>
                <div class="launch-text" v-if="launchTitle === 'welcome'">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Image title</span>
                            <span style="float:right;">Montserrat - Light</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">图片标题</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Light</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>

                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.conSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                    <StyleDown v-model="editObj.en.conLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.conLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.conColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Text</span>
                            <span style="float:right;">Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.content" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">文本</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.content" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
            </div>

            <!--s: Content-->
            <div class="launch-box launch-margin-top" v-if="launchTitle === 'welcome'">
                <div class="launch-terminal">
                    <a href="javascript:" :class="typeStatus === item.cid?'select':''" :key="key" v-for="(item,key) in editObj.en.productList" @click="onContent(item.cid)">{{ item.name }}</a>
                </div>
                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.productList[typeStatus-1].proList.tSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                    <StyleDown v-model="editObj.en.productList[typeStatus-1].proList.tLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.productList[typeStatus-1].proList.tLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.productList[typeStatus-1].proList.tColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-image-list" :key="key" v-for="(item,key) in editObj.en.productList[typeStatus-1].proList">
                    <div class="launch-text image-text" style="margin-bottom: 0px">
                        <div class="launch-upload margin-bottom">
                            <span class="title">Image {{ key+1 }} - 250 x 250</span>
                            <div class="upload">
                                <span class="value">{{ item.image }}</span>
                                <Button>Upload<input type="file" class="input" @change="onImgDataChange($event,key)"/></Button>
                            </div>
                        </div>
                        <div class="box">
                            <div class="title">
                                <span class="name store">Text</span>
                                <span style="float:right;">Montserrat - Bold</span>
                            </div>
                            <div class="input-box">
                                <input v-model="item.name" type="text" placeholder="Brand / Category / Category II" class="input"/>
                            </div>
                        </div>
                        <div class="box">
                            <div class="title">
                                <span class="name store">文本</span>
                                <span style="float:right;">方正兰亭 - 粗黑 / Montserrat - Bold</span>
                            </div>
                            <div class="input-box">
                                <input v-model="item.cName" type="text" placeholder="品牌 / 分类 / 二级分类" class="input"/>
                            </div>
                        </div>
                    </div>
                    <div class="launch-select-btn" style="margin-top: 0px">
                        <a href="javascript:" class="select" @click="onSelectProduct(key)">Select / Edit Product List</a>
                    </div>
                </div>
            </div>
            <!--e: Content-->

            <!--s: Btn-->
            <div class="launch-btn-edit">
                <div class="box">
                    <Button style="margin-left: 30px" :loading="loadingStatus" @click="onEditSubmit">Save</Button>
                </div>
            </div>
            <!--s: Btn-->

        </div>
        <!--e: Launch -->

        <!--s: Modal-->
        <div class="layout-select-product" v-if="selectVisible">
            <ProductSelect :data="selectList" @on-edm-select-product="onEdmSelectProduct"/>
        </div>
        <!--e: Modal-->

    </div>
</template>
<script>
import ReplyEdit from './replyEdit'
export default ReplyEdit
</script>
<style lang="less">
@import "replyEdit";
</style>
