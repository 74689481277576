import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 分页获取待处理的商品列表
 * @param params
 * @returns {Promise<*>}
 */
export const getShopSupService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/spu/get/pending?brandIds='+params.brandIds+'&attributeIds='+params.attributeIds+'&isSale='+params.isSale+'&isNew='+params.isNew+'&isOld='+params.isOld+'&isStar='+params.isStar+'&isShow='+params.isShow+'&isSole='+params.isSole+'&price2='+params.price2+'&cid1='+params.cid1+'&cid2='+params.cid2+'&cid3='+params.cid3+'&cid4='+params.cid4+'&cid5='+params.cid5+'&size='+params.size+'&page='+params.page+'&status=0'))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
