import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 获取前端所需菜单
 * @param params
 * @returns {Promise<*>}
 */
export const getMenusBuildService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/menus/build'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 获取左侧菜单
 * @param params
 * @returns {Promise<*>}
 */
export const getMenuListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/attribute/get/list?type=0&id='+params.id),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


