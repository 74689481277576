/**
 * 产品信息列表选择
 */
import Vue from 'vue'
import { Spin,Icon,Page,Input } from 'iview';
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('Spin', Spin);
Vue.component('Icon', Icon);
import Header from '@/components/Header/header.vue'
import ProductType from '@/components/ProductType/productType.vue'
import ProductMenu from '@/components/ProductMenu/productMenu.vue'
import { mapMutations, mapState } from "vuex";
import { discountList,getGroupListService,getGroupLabelService } from "@/service/spu-group-service"
import { dataUnit, timeUnit } from "@/utils/tools";
const ProductSelect = {
    name:'ProductList',
    props:{
        /**
         * 自定义数据
         */
        data:{
            type:Array,
        },
    },
    components:{
        Header,
        ProductType,
        ProductMenu
    },
    computed: {
        ...mapState({
            userName: state => state.userCenter.userName,  //获取状态管理用户名称
            selectList: state => state.product.selectList, //获取状态管理选择的商品
        }),
    },
    data() {
        return {
            dataUnit:dataUnit,
            timeUnit:timeUnit,
            loadingVisible:true,
            discountList:discountList,
            productList:{}, //商品列表对象
            productSelect:[],//选中的商品
            productParams:{
                classifySelect:'', //选择分类
                discount:'', //选择折扣
                cid1:1, //一级分类
                cid2:'',
                cid3:'',
                cid4:'',
                cid5:'',
                isSale:'', //折扣
                isNew:'', //新款
                isOld:'', //古着
                isStar:0, //星选
                isShow:0, //秀款
                isSole:0, //独家
                prices:[], //价格
                brandsSelect:'', //品牌
                attributeIds:'', //属性
                currentPage:1,
                pageSize:12
            },
        }
    },
    created() {
        this.productSelect = this.data
        this.onGroupList().then()
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID','SET_SELECT_LIST']),
        /**
         * 选择折扣
         * @param id
         */
        onDiscount(id) {
            this.productParams.discount = id
            this.onGroupList().then()
        },
        /**
         * 选择筛选条件
         * @param name
         */
        onSelectSearchType(name){
            switch (name){
                case 'isStar':
                    this.productParams.isStar === 0?this.productParams.isStar = 1 : this.productParams.isStar = 0
                    break;
                case 'isShow':
                    this.productParams.isShow === 0?this.productParams.isShow = 1 : this.productParams.isShow = 0
                    break;
                case 'isSole':
                    this.productParams.isSole === 0?this.productParams.isSole = 1 : this.productParams.isSole = 0
                    break;
            }
            this.onGroupList().then()
        },
        /**
         * 选中商品
         * @param obj
         */
        onProductSelect(obj) {
            obj.checked = !obj.checked;
            if(this.productSelect.length === 0){
                this.productSelect.push(obj.id)
            }else{
                let index = this.productSelect.findIndex((id) => id === obj.id)
                if(this.productSelect.findIndex((id) => id === obj.id) !==-1){
                    this.productSelect.splice(index,1)
                }else{
                    this.productSelect.push(obj.id)
                }
            }
        },
        /**
         * 保存选择的商品id
         */
        onSave(){
            this.$emit('on-edm-select-product',this.productSelect)
        },
        /**
         * 选择一级分类
         */
        onSelectType(id){
            this.productParams.cid1 = id
            this.onGroupList().then()
        },
        /**
         * 价格
         * @param price
         */
        onPrice(price){
            this.loadingVisible = true
            this.productParams.prices = price
            this.onGroupList().then()
        },
        /**
         * 选择分类
         * @param obj
         */
        onSelectClassify(obj){
            if(obj.level === 2){
                this.productParams.cid2 = obj.id
            }
            if(obj.level === 3){
                if(obj.isParent === 0){
                    let classifySelect = ''
                    for(let i in obj.list){
                        classifySelect += obj.list[i].id+','
                    }
                    this.productParams.cid3 = classifySelect
                }else{
                    this.productParams.cid3 = obj.id
                }
            }
            if(obj.level === 4){
                if(obj.isParent === 0){
                    let classifySelect = ''
                    for(let i in obj.list){
                        classifySelect += obj.list[i].id+','
                    }
                    this.productParams.cid4 = classifySelect
                }else{
                    this.productParams.cid4 = obj.id
                }
            }
            if(obj.level === 5){
                if(obj.isParent === 0){
                    let classifySelect = ''
                    for(let i in obj.list){
                        classifySelect += obj.list[i].id+','
                    }
                    this.productParams.cid5 = classifySelect
                }else{
                    this.productParams.cid5 = obj.id
                }
            }
            this.onGroupList().then()
        },
        /**
         * 品牌筛选条件
         * @param list
         */
        onSelectBrands(list){
            this.productParams.brandsSelect = ''
            for(let i in list){
                this.productParams.brandsSelect += list[i].id+','
            }
            this.onGroupList().then()
        },
        /**
         * 属性筛选条件
         * @param list
         */
        onSelectAttribute(list){
            this.productParams.attributeIds = ''
            for(let i in list){
                this.productParams.attributeIds += list[i].id+','
            }
            this.onGroupList().then()
        },
        /**
         * 新款古着折扣条件筛选
         * @param obj
         */
        onSelectNewIn(obj){
            this.productParams.isSale = obj.isSale
            this.productParams.isOld = obj.isOld
            this.productParams.isNew = obj.isNew
            this.onGroupList().then()
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.productParams.currentPage = number
            this.onGroupList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.productParams.pageSize = number
            this.onGroupList().then()
        },
        /**
         * 拉取商品列表
         * @returns {Promise<void>}
         */
        async onGroupList() {
            try {
                this.loadingVisible = true
                let params = {
                    'brandIds':this.productParams.brandsSelect,
                    'attributeIds':this.productParams.attributeIds,
                    'price2':this.productParams.prices.length!==0?this.productParams.prices[0]+','+this.productParams.prices[1]:'',
                    'cid1':this.productParams.cid1,
                    'cid2':this.productParams.cid2,
                    'cid3':this.productParams.cid3,
                    'cid4':this.productParams.cid4,
                    'cid5':this.productParams.cid5,
                    'isSale':this.productParams.isSale,
                    'isNew':this.productParams.isNew,
                    'isOld':this.productParams.isOld,
                    'isStar':this.productParams.isStar,
                    'isShow':this.productParams.isShow,
                    'isSole':this.productParams.isSole,
                    'size':this.productParams.pageSize,
                    'page':this.productParams.currentPage,
                    'status':0
                }
                const data = await getGroupListService(params)
                for(let i in data.content){
                    data.content[i].checked = false
                }
                for(let i in this.data){
                    for(let j in data.content){
                        if(this.data[i] === data.content[j].id){
                            data.content[j].checked = true
                        }
                    }
                }
                this.productList = data
                this.loadingVisible = false
            }catch (error){
                this.loadingVisible = false
                console.log(error)
            }
        },
        /**
         * 设置商品组标签
         * @returns {Promise<void>}
         */
        async onGroupLabel(id,state,type){
            try {
                let params = {
                    'id':id,
                    'state':state===0?state=1:state=0,
                    'type':type,
                }
                const { code } = await getGroupLabelService(params)
                if( code === 1){
                    this.onGroupList().then()
                }else{
                    alert('操作失败！')
                }

            }catch (error){
                console.log(error)
            }
        }
    },
}
export default ProductSelect
