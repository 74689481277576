import { classify } from "@/service/spu-service";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
/**
 * 商品分类
 * @type {{data(): {}}}
 */
const ProductType = {
    name:'ProductType',
    props:{
        /**
         * back
         */
        back:{
            type:String,
        },
    },
    data(){
        return {
            classifyObj:classify, //分类对象
            classifyVisible:false, //选择分类弹框可显示
            classifySelect:classify[0], //选择分类的对象
        }
    },
    mounted() {
        /**
         * 点击空白处消除弹框
         */
        document.addEventListener('click',(e) => {
            let thisClassName = e.target.className;
            if(thisClassName !== 'type-box' && thisClassName !== 'type-name' && thisClassName !== 'type-icon'){
                this.classifyVisible = false
            }
        })
    },
    methods:{
        /**
         * 返回至目录页
         */
        onBack() {
            if(this.back === 'close'){
                $event.$emit(EVENT_NAME.SELECT_PRODUCT_CLOSE)
            }else{
                this.$router.back()
            }
        },
        /**
         * 选择分类
         * @param value
         */
        onSelectType(obj) {
            this.classifySelect = obj;
            this.$emit('on-select-type',obj.value)
            $event.$emit('SELECT_CLASSIFY_TYPE',obj.value)
        },
    }
}
export default ProductType
