<template>
    <div class="type-down" @click="typeVisible?typeVisible = false:typeVisible = true" :style="'width:'+width+'px;margin-right:'+marginRight+'px'">
        <div class="title type-down-title" :class="value !==''?'type-hover':''">
            <span class="name type-down-name">{{ title }}<i class="clear" @click.stop="onClear"></i></span>
            <i class="down type-down-i"></i>
        </div>
        <div class="text">{{ defaultSelect }}</div>
        <div class="type-select" v-show="typeVisible">
            <ul>
                <li :class="value === dataType.id?'active':''" :key="key" v-for="(dataType,key) in data" @click="onSelect(dataType)">{{ dataType.name }}</li>
            </ul>
        </div>
    </div>
</template>
<script>
import TypeDown from './typeDown'
export default TypeDown
</script>
<style lang="less">
@import "typeDown";
</style>
